import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import BottomNavigation from 'components/BottomNavigation';

// fonts
import 'styles/fonts/DIN2014-Bold/stylesheet.css';
import 'styles/fonts/DIN2014-Demi/stylesheet.css';
import 'styles/fonts/DIN2014-Regular/stylesheet.css';
import 'styles/fonts/OstrichProper-Bold/stylesheet.css';
import 'styles/fonts/OstrichProperInline-Open/stylesheet.css';

// styles
import theme from 'styles/theme';
import 'styles/reset.css';
import 'styles/styles.css';

const StyledLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.nearWhite};
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

// This is the element that will be after the header in most of the pages
export const PageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 100px;
`;

function Layout({ bottomNav, children, navProps = {}, ...rest }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledLayout {...rest}>
        <ContentWrapper>{children}</ContentWrapper>
        {bottomNav ? bottomNav : <BottomNavigation {...navProps} />}
      </StyledLayout>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  bottomNav: PropTypes.any,
  children: PropTypes.any.isRequired,
  navProps: PropTypes.object,
};

Layout.defaultProps = {
  bottomNav: null,
};

export default Layout;
