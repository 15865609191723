import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import Loading from 'components/Loading';

const StyledButton = styled.button`
  align-items: center;
  background-color: ${({ bgColor, theme }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.green};
  border: none;
  box-sizing: border-box;
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.black};
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  justify-content: center;
  letter-spacing: 1.8px;
  line-height: 1.2;
  min-height: 41px;
  height: 48px;
  padding: 0 43px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  :active,
  :hover {
    color: #fff;
    background: #000;
  }
  :disabled {
    background-color: ${({ theme }) => theme.colors.gray};
  }
  ${({ secondary, theme }) =>
    secondary &&
    `
    background-color: ${theme.colors.mediumGray};
    :hover {
      color: #fff;
    }
  `}

  ${({ outline, theme }) =>
    outline &&
    `
    border: 2px solid ${theme.colors.green};
    background-color: transparent; 
      color: ${theme.colors.white};
    :hover {
      color: ${theme.colors.black};
      background-color: ${theme.colors.green};
    }
  `}
  ${({ theme }) => theme.fonts.dinRegular}

  ${({ disabled, theme }) =>
    disabled &&
    `
  background-color: ${theme.colors.mediumGray};
  pointer-events: none;
  `};
`;

const Offset = styled.span`
  transform: translateY(1px);
`;

function Button({ children, loading, to, ...rest }) {
  const buttonProps = {
    as: to ? GatsbyLink : 'button',
    to: to || undefined,
    ...rest,
  };
  return (
    <StyledButton {...buttonProps}>
      <Offset>{loading ? <Loading /> : children}</Offset>
    </StyledButton>
  );
}

Button.propTypes = {
  loading: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
};

export default Button;
