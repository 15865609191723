import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from 'components/Button';

import { media } from 'styles/theme';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// MTS - This is required or else we get a styled-components error.
const StyledButton = styled(Button)``;

const multipleButtons = css`
  ${StyledButton}:first-of-type {
    background-color: white;
    color: ${({ theme }) => theme.colors.black};
    margin-right: 8px;
    :hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const StyledBottomNavigation = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.nearWhite};
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 75px;
  justify-content: center;

  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  ${media.ns} {
    height: 100px;
  }
  ${({ children }) => children?.filter(Boolean)?.length > 1 && multipleButtons}
`;

function BottomNavigation({
  children,
  firstPage,
  backButton,
  hideNav,
  nextButton,
}) {
  const { t } = useTranslation(['common']);

  if (hideNav) {
    return null;
  }

  return (
    <StyledBottomNavigation>
      {!firstPage && (
        <StyledButton
          children={backButton?.children || t('common:back')}
          {...backButton}
        />
      )}
      <StyledButton
        children={nextButton?.children || t('common:next')}
        {...nextButton}
      />
    </StyledBottomNavigation>
  );
}

BottomNavigation.propTypes = {
  hideNav: PropTypes.bool,
  firstPage: PropTypes.bool.isRequired,
};

BottomNavigation.defaultProps = {
  hideNav: false,
  firstPage: false,
};

export default BottomNavigation;
